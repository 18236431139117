import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import Layout from '../layouts/Layout';
import { navigate } from 'gatsby';
import firebase from 'gatsby-plugin-firebase';
import useFirebaseAuth from '../hooks/useFirebaseAuth';

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  font-size: 1em;
`;

const SubmitButton = styled.button``;

const SuccessMessage = styled.span`
  padding: 12px 8px;
  background: #c9e8cb;
  color: #1ca525;
`;

const ErrorMessage = styled.span`
  padding: 12px 8px;
  background: #f4adad;
  color: #e22424;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LoginPage: React.FC = function () {
  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { loading, currentUser } = useFirebaseAuth();

  // Check if the current user is logged in
  useEffect(() => {
    if (!loading && currentUser !== null) {
      navigate('/dashboard');
    }
  }, [loading, currentUser]);

  const handleSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();

      async function login() {
        setErrorMessage(null);
        setSuccess(false);
        try {
          await firebase.auth().sendPasswordResetEmail(email);
          setSuccess(true);
        } catch (e) {
          setErrorMessage(e.message);
        }
      }

      login();
    },
    [email]
  );

  return (
    <Layout>
      <Wrapper>
        <Form>
          <Input
            onChange={e => setEmail(e.target.value)}
            value={email}
            type="email"
            placeholder="Email"
          />
          {success && (
            <SuccessMessage>
              A password reset email has been sent to your inbox.
            </SuccessMessage>
          )}
          {errorMessage && <ErrorMessage>An error occurred.</ErrorMessage>}
          <SubmitButton onClick={handleSubmit}>Reset Password</SubmitButton>
        </Form>
      </Wrapper>
    </Layout>
  );
};

export default LoginPage;
